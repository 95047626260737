/* Assuming this CSS is in a file like styles.css */
.btn {
  position: relative;
  padding-bottom: 0.5rem; /* Ensures there's space for the underline */
}

.btn::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0.175rem;
  left: 0;
  bottom: 0;
  background: #B7BDF8;
  transform: scale(0, 1);
  transform-origin: 0% 100%;
  transition: transform 0.3s ease;
}

.btn:hover::after {
  transform: scale(1, 1);
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}

.nav-hover:hover{
  transition: 1s;
  color: transparent;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  background-clip: text;
  --tw-gradient-from: rgb(var(--ctp-mauve)) var(--tw-gradient-from-position);
    --tw-gradient-to: rgba(var(--ctp-mauve), 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: rgb(var(--ctp-lavender)) var(--tw-gradient-to-position);
}